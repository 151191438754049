/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import '../../assets/css/custom.css';
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Icon } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function HeaderLinksLeft(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <List className={classes.list}>
      <ListItem className={classes.listItem} style={{float: 'left'}}>
          
          <Button
            color="transparent"
            href="/"
            target="_self"
            className={classes.navLink}
          >
            <div class="navbar-logo"></div>
          </Button>
      </ListItem>

      {/* <ListItem className={classes.listItem}>
          
          <Button
            color="transparent"
            onClick={() => props.handleScrollToElement()}
            target="_blank"
            className={classes.navLink}
          >
            <h3 className="navbar-item">Сервиси</h3>
          </Button>
      </ListItem> */}
        <ListItem className={classes.listItem}>
          
            <Button
              color="transparent"
              href="/about"
              target="_self"
              className={classes.navLink}
            >
              <h3 className="navbar-item">За Нас</h3>
            </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          
            <Button
              color="transparent"
              href="/home"
              target="_self"
              className={classes.navLink}
            >
              <h3 className="navbar-item">Дома</h3>
            </Button>
        </ListItem>
      </List>
    </React.Fragment>
  );
}
