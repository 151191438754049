import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import '../../assets/css/custom.css';

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import HeaderLinksLeft from "components/Header/HeaderLinksLeft.js"

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
import { Icon, List, ListItem, Tooltip } from "@material-ui/core";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const myRef = React.createRef();

  const handleScrollToElement = () => {
    if (true) {
      window.scrollTo({ top: 15000, behavior: "smooth" });
    }
  };
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        // brand="SGAUTO LOGO"
          
        rightLinks={
          <HeaderLinks
          color="white"
            handleScrollToElement={() => handleScrollToElement()}
          ></HeaderLinks>
        }
        leftLinks={
          <HeaderLinksLeft
            handleScrollToElement={() => handleScrollToElement()}
          ></HeaderLinksLeft>
        }
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/landing-bg4.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} className="hide-contact">
              <Button
                color="danger"
                size="lg"
                href="tel:+38975555566"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon>call</Icon>
                КОНТАКТ
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          {/* <TeamSection /> */}
          <div ref={myRef}>
            <div
            // style={{
            //   backgroundColor: "white",
            //   minWidth: "85%",
            //   height: "55px",
            //   marginTop: "0px",
            //   position: "absolute",
            // }}
            ></div>
            {/* <iframe
              src="https://www.google.com/maps/d/u/0/embed?mid=1U_pGMYVRdZpX5pGwQqw8cOlPo9RP5KwG"
              width="100%"
              height="600"
            ></iframe> */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d900.5498558710151!2d21.40954508433545!3d42.018100627174974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13541591d4a286f9%3A0x579dbfe1e0a3ec2a!2sSG%20AUTO%20SKOPJE!5e0!3m2!1sen!2smk!4v1620251214240!5m2!1sen!2smk"
              width="100%"
              height="600"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
