import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Settings";
import VerifiedUser from "@material-ui/icons/SettingsInputComponent";
import Fingerprint from "@material-ui/icons/LocalCarWash";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          {/* <h2 className={classes.title}>Што е Lorem Ipsum?</h2> */}
          {/* <h5 className={classes.description}>
            Lorem Ipsum е едноставен модел на текст кој се користел во
            печатарската индустрија. Lorem ipsum бил индустриски стандард кој се
            користел како модел уште пред 1500 години, кога непознат печатар зел
            кутија со букви и ги сложил на таков начин за да направи примерок на
            книга.
          </h5> */}
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="АВТОМЕХАНИКА"
              // description="Познат е фактот дека вниманието на читателот ќе биде привлечено од содржината на страната која ја гледа. Поентата за користење на Lorem Ipsum е во тоа што распоредот на зборовите е речиси нормален"
              imageSrc={require('../../../assets/img/Icon1.png')}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="АВТОЕЛЕКТРОНИКА"
              // description="Познат е фактот дека вниманието на читателот ќе биде привлечено од содржината на страната која ја гледа. Поентата за користење на Lorem Ipsum е во тоа што распоредот на зборовите е речиси нормален"
              imageSrc={require('../../../assets/img/Icon2.png')}
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="ЛИМАРО ФАРБАРСКИ УСЛУГИ"
              // description="Познат е фактот дека вниманието на читателот ќе биде привлечено од содржината на страната која ја гледа. Поентата за користење на Lorem Ipsum е во тоа што распоредот на зборовите е речиси нормален"
              imageSrc={require('../../../assets/img/Icon3.png')}
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
