/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.center}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="http://sgauto.mk"
                className={classes.block}
                target="_blank"
              >
                СГАУТО ДООЕЛ
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a className={classes.block} target="_blank">
                Контакт телефон : 075555566
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.google.com/maps/place/SG+AUTO+SKOPJE/@42.018242,21.409479,18z/data=!4m5!3m4!1s0x0:0x579dbfe1e0a3ec2a!8m2!3d42.0182423!4d21.4094792?hl=en"
                className={classes.block}
                target="_blank"
              >
                Адреса: Момин Поток, ул.1605 бр.38
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.sgauto.mk/contact"
                className={classes.block}
                target="_blank"
              >
                Е-маил: info@sgauto.mk
              </a>
            </ListItem>
          </List>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
