import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import InfoPage from "views/InfoPage/InfoPage.js";
// import ServicePage from "views/ServicePage/ServicePage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route exact path="/">
          <Redirect to="/home" />
      </Route>
      <Route exact path="/home" component={LandingPage} />
      <Route exact path="/about" component={InfoPage} />
      {/* <Route path="/services" component={ServicePage} /> */}
      {/* <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/" component={Components} /> */}
    </Switch>
  </Router>,
  document.getElementById("root")
);
