import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import HeaderLinksLeft from "components/Header/HeaderLinksLeft.js"

import styles from "assets/jss/material-kit-react/views/infoPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
import { Icon, List, ListItem, Tooltip } from "@material-ui/core";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function InfoPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const myRef = React.createRef();

  const handleScrollToElement = () => {
    if (true) {
      window.scrollTo({ top: 15000, behavior: "smooth" });
    }
  };
  return (
    <div className="body-info">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        // brand="SGAUTO LOGO"
          
        rightLinks={
          <HeaderLinks
          color="black"
            handleScrollToElement={() => handleScrollToElement()}
          ></HeaderLinks>
        }
        leftLinks={
          <HeaderLinksLeft
          color="black"
            handleScrollToElement={() => handleScrollToElement()}
          ></HeaderLinksLeft>
        }
        fixed
        changeColorOnScroll={{
          height: 0,
          color: "white",
        }}
        {...rest}
      />
      <div class="info-text-wrapper">
        <div className="about-us-wrapper">
            <h3 className="h3-about-us">СГ АУТО</h3>
            <p className="p-about-us">
            СГ АУТО е сервисен центар основан во 2006 година. Професионализмот за нас е од витално значење, затоа веќе 15 години секојдневно еволуираме во еден од најдобрите сервиси оставајќи зад себе голем број на задоволни клиенти. За квалитетно извршените услуги, располагаме со сопствен затворен работен простор од 1000м2 со 6 авто дигалки, каролинер за извлекување на хаварисани возила и комора за фарбање. Канцалариски простор за административен персонал за оперативна обработка на документација и прием на возила. Голем сопствен паркинг простор со обезбеден 24 часовен видео надзор и аларм.
            </p>
        </div>

        <div className="references-wrapper">
            <h3 className="h3-references">РЕФЕРЕНТНА ЛИСТА</h3>
            <ul className="ul-references">
                <li> АГЕНЦИЈА ЗА ЦИВИЛНО ВОЗДУХОПЛОВСТВО</li>
                <li>АГЕНЦИЈА ЗА ДРЖАВНИ СЛУЖБЕНИЦИ</li>
                <li>АГЕНЦИЈА ЗА РАЗУЗНАВАЊЕ</li>
                <li>АЕРОДРОМ АЛЕКСАНДАР ВЕЛИКИ</li>
                <li>АЛПИНЕ БАУ ГМБХ</li>
                <li>АМБАСАДА НА РЕПУБЛИКА ГРЦИЈА</li>
                <li>АМЕРИКАНСКА АМБАСАДА</li>
                <li>БРИЛИЈАНТ</li>
                <li>ВЛАДА НА РМ</li>
                <li>ВОДОВОД И КАНАЛИЗАЦИЈА</li>
                <li>ГЕМАК ТРЕЈД</li>
                <li>ДРОГА КОЛИНСКА</li>
                <li>ЕУММ</li>
                <li>ЕУРО ТАБАК</li>
                <li>КАБИНЕТ НА ПРЕТСЕДАТЕЛ НА Р.МАКЕДОНИЈА</li>
                <li>КРКА ФАРМА</li>
                <li>ЛЕК СКОПЈЕ</li>
                <li>МВР на РМ</li>
                <li>МАКЕДОНСКА РАДИО ДИФУЗИЈА</li>
                <li>МЕПСО АД</li>
                <li>МИК СВЕТИ НИКОЛЕ</li>
                <li>МИНИСТЕРСТВО ЗА ЗДРАВСТВО</li>
                <li>МИНИСТЕРСТВО ЗА ОДБРАНА</li>
                <li>НЕОТЕЛ доо</li>
                <li>НЛБ БАНКА</li>
                <li>ОПШТИНА КИСЕЛА ВОДА</li>
                <li>ОПШТИНА ЦЕНТАР</li>
                <li>ПРИЛЕПСКА ПИВАРНИЦА</li>
                <li>ПРОКРЕДИТ БАНКА</li>
                <li>РАДЕ КОНЧАР</li>
                <li>РЕПЛЕК ФАРМА</li>
                <li>УПРАВА ЗА ЈАВНИ ПРИХОДИ</li>
                <li>СЛУЖБЕН ВЕСНИК</li>
                <li>ЈП ТОПЛИФИКАЦИЈА АД</li>
                <li>ЦАРИНСКА УПРАВА</li>
                <li>ФИОО - МАКЕДОНИЈА</li>
                <li>ФОНД ЗА МАГ. РЕГ. ПАТИШТА</li>
                <li>ФРАНЦУСКА АМБАСАДА</li>
                <li>ЗЕМЈОДЕЛСКИ ИНСТИТУТ</li>
            </ul>
        </div>
    </div>
      <Footer />
    </div>
  );
}
